<template>
  <div class="d-flex justify-content-center">
    <p class="mb-0">
      <span class=" mt-25">
        Copyright © {{ new Date().getFullYear() }}
        <b-link class="ml-25" href="#" target="_blank">Onetoko</b-link>
        <span class="">, All rights Reserved</span>
      </span>

      <!-- <span class="float-md-right d-none d-md-block"
      >Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
    </p>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
};
</script>
