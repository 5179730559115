export default [
  {
    title: 'Dashboard',
    route: 'SubscribeDashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Subscription Plan',
    route: 'subscription-plan',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Transactions',
    route: 'transactions',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Faqs',
    route: 'faqs',
    icon: 'ClipboardIcon',
  },
]
