<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <div class="full-width" v-if="getSubscriptionInfo">
      <b-row>
        <b-col cols="12" xl="12" class="d-flex flex-wrap align-items-center">
          <div class="px-50">
            <b-badge variant="light-danger">
              {{ getSubscriptionInfo.plan_name }} Plan
            </b-badge>
          </div>
          <div class="px-0 small d-flex flex-wrap align-items-center">
            Expire On :
            <span class="text-capitalize">
              &nbsp;{{ getSubscriptionInfo.end_date }}</span
            >
          </div>

          <div class="px-50">
            <b-button variant="success" size="sm" class="" @click="extendPlan()">
              <b-spinner v-if="loading" small />
              Extend
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto" v-if="getUserInfo">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getUserInfo.name }}
            </p>
            <span class="user-status">{{ getUserInfo.company_name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getUserInfo.profile_pic"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({ name: 'update-profile' })"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Update Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

   
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import store from '@/store/index';

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BButton,
    BSpinner,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return{
      loading:false,
    }
  },
  computed: {
    ...mapGetters({
      getSubscriptionInfo: 'app-dashboard/getSubscriptionInfo',
      getUserInfo: 'app-dashboard/getUserInfo',
    }),
  },

  created() {
    if (!this.$cookies.get('token')) this.$router.push('/login');
  },
  methods: {
    logout() {
      this.$cookies.remove('token');
      this.$cookies.remove('company_id');
      this.$router.push({ name: 'login' });
    },
    /* extendPlan(){
      axios.get('/subscription/payment/', {plan_id: 1, plan_price_id: 2})
      .then((response)=>{
        console.log(response);
      })
      .catch(()=>{})
    }, */
    extendPlan() {
     /*  this.loading = true;
      store.dispatch('app-customer/extendPlan', {plan_id: this.getSubscriptionInfo.plan_id, plan_price_id: 2}).then((response) => {
          if (response.data.result.code === 200) {
            this.loading = false;
            window.open(response.data.result.data.url, "_self");
          } else {
            this.loading = false;
            this.$bvToast.toast('Something went wrong', {
              title: 'Failed',
              variant: 'danger',
              solid: true,
            });
          }
      }); */
      this.$router.push('/subscription-plan')
      //store.dispatch('app-dashboard/fetchSubscribeDashboard').then(() => {});
    },
  },
};
</script>
